import * as React from 'react';

function IconColon(props) {
  return (
    <svg width={3} height={12} fill="none" {...props}>
      <path
        d="M1.5 12c-.411 0-.764-.141-1.058-.424A1.359 1.359 0 010 10.56c0-.395.147-.733.442-1.016A1.474 1.474 0 011.5 9.12c.411 0 .764.141 1.058.424.295.283.442.621.442 1.016 0 .261-.07.501-.208.72A1.475 1.475 0 011.5 12zm0-9.12c-.411 0-.764-.141-1.058-.424A1.359 1.359 0 010 1.44C0 1.045.147.707.442.424A1.474 1.474 0 011.5 0c.411 0 .764.141 1.058.424C2.853.707 3 1.045 3 1.44c0 .261-.07.501-.208.72a1.475 1.475 0 01-1.292.72z"
        fill="#AECFBB"
      />
    </svg>
  );
}

const MemoIconColon = React.memo(IconColon);
export default MemoIconColon;
