/* eslint-disable react/prop-types */
import * as React from 'react';

import styled from 'styled-components';

import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';
import { FixedTopBarScholarshipScheduleNOffline } from '../shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import ScholarshipBannerMain from '../shared/SchedulePage/ScholarshipBanner';
import { SectionWhyDOL } from '../shared/SchedulePage/SectionWhyDOL';
import { ScheduleByProgramSection } from '../shared/SchedulePage/sections/ScheduleByProgramSection';
import SectionDolCourseCalendar from '../shared/SchedulePage/SectionDolCourseCalendar';
import { BranchOffice } from 'shared/Center/BranchOffice';
import { withHomePageSection } from 'components/withHomePageSection';

import { fromScreen } from 'utils/media-query/responsive.util';

const Space = styled.div`
  > div {
    margin-bottom: 64px;
    &:last-child,
    &:first-child {
      margin-bottom: 32px;
    }

    ${fromScreen(776)} {
      margin-bottom: 100px;
      &:first-child {
        margin-bottom: 50px;
      }
      &:last-child {
        margin-bottom: 100px;
      }
    }

    ${fromScreen(1144)} {
      &:first-child {
        margin-bottom: 100px;
      }
    }
  }
`;
const ContentSection = withHomePageSection(() => {
  return (
    <Space>
      <ScholarshipBannerMain />
      <SectionDolCourseCalendar />
      <BranchOffice
        title="Lịch học theo cơ sở"
        desc="Hiện DOL đang có mặt ở hơn 10 quận tại TP.HCM, 3 cơ sở ở Hà Nội cùng 1 cơ sở tại Đà Nẵng và nền tảng
        Online Zoom."
        listedSchedule
      />
      <ScheduleByProgramSection />
      <SectionWhyDOL />
    </Space>
  );
});
const SchedulePage = ({ location }) => {
  return (
    <MainLayout
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      noHeader
    >
      <MySEO
        slug={location.pathname}
        h1="Lịch Khai Giảng Các Khóa Học IELTS - DOL English"
        title="Lịch Khai Giảng Các Khóa Học IELTS - DOL English"
        description="Lịch khai giảng và ưu đãi hàng tháng của các khóa Tiếng Anh IELTS tại DOL IELTS Đình Lực. Các khóa học Online và Offline được cập nhật thường xuyên, liên tục."
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <ContentSection />
    </MainLayout>
  );
};

export default SchedulePage;
