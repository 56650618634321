import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { fromScreen } from '../../utils/media-query/responsive.util';

const data = [
  {
    logo: 'OOAcl6G1T3qrWr9W9ZBa',
    title: 'Hệ phương pháp<br/> Linearthinking',
    items: [
      'Học Tiếng Anh Tư Duy, thông minh',
      'Điểm cao, rút ngắn 1/2 thời gian',
      'Ứng dụng tốt trong trường và giao tiếp'
    ]
  },
  {
    logo: 'PCeZeNsrTb2K4l72Lf3n',
    title: 'Giáo viên tận tâm',
    items: [
      'Giáo viên khả năng sư phạm cao',
      'Tận tâm với học viên',
      'Profile tốt, điểm 8.0 8.5 9.0'
    ]
  },
  {
    logo: '3oE3eKirTOCCY0Ip3Tbj',
    title: 'Nền tảng công nghệ',
    items: [
      'Độc quyền, chưa có trên thị trường',
      'Mượt, và nội dung xịn',
      'Chứa tất cả tính năng học viên cần'
    ]
  }
];

// INFO CARD
const InfoCardLogo = styled.img`
  width: 40px;
  height: 40px;
`;
const InfoCardText = styled(Typography).attrs({
  variant: 'regular/14-20'
})``;
const InfoCardTextLI = styled.li``;
const InfoCardTextList = styled.ul`
  padding-inline-start: 28px;
  margin: 8px 0 0;
`;
const InfoCardWrapper = styled.div`
  border: 1px solid ${colorsV2.gray20};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
`;
const InfoCard = ({ logo, title, items }) => {
  return (
    <InfoCardWrapper>
      <InfoCardLogo src={getAssetLinkByHandle({ handle: logo })} />
      <Typography
        variant="semi-bold/20-28"
        as="h3"
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />

      <InfoCardTextList>
        {items.length > 0 &&
          items.map((item, index) => (
            <InfoCardTextLI key={index}>
              <InfoCardText>{item}</InfoCardText>
            </InfoCardTextLI>
          ))}
      </InfoCardTextList>
    </InfoCardWrapper>
  );
};

// LAYOUT
const InfoCardList = styled.div`
  display: grid;
  grid-gap: 16px;

  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
  ${fromScreen(1144)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const SectionWhyDOLWrapper = styled.div`
  .section-title {
    margin-bottom: 24px;
  }
`;
export const SectionWhyDOL = () => {
  return (
    <SectionWhyDOLWrapper>
      <Typography
        v3
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        className="section-title"
        as="h2"
      >
        Tại sao phải đến DOL học ?
      </Typography>

      <InfoCardList>
        {data.map((it, index) => (
          <InfoCard key={index} {...it} />
        ))}
      </InfoCardList>
    </SectionWhyDOLWrapper>
  );
};
