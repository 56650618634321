import * as React from 'react';

function IconInterfaceCalendar(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16 2v4M8 2v4M3 9h18"
        stroke="#242938"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M19 4H5a2 2 0 00-2 2v13a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2z"
        stroke="#242938"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9v12M15 9v12M3 15h18"
        stroke="#242938"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconInterfaceCalendar = React.memo(IconInterfaceCalendar);
export default MemoIconInterfaceCalendar;
