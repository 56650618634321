import groupBy from 'lodash/groupBy';
import format from 'date-fns/format';
import vi from 'date-fns/locale/vi';
import { formatClassTime, formatDayOfWeek } from '../../../utils/dateFormat';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import { graphql, useStaticQuery } from 'gatsby';

const courseCalendarQuery = graphql`
  query DolCourseCalendarQuery {
    allGoogleSpreadsheetDolLandingPageMarLevelData(
      filter: { classListing: { eq: "listed" } }
      sort: { order: ASC, fields: classGroupScheduleOrder }
    ) {
      group(field: classGroup) {
        edges {
          node {
            id
            classDate
            classGroup
            classListing
            classTime
            inputLevel
            level
            levelDescription
            levelDisplay
            outputLevel
            startDate
            teacherRw
            teacherSl
            classCode
            classStatus
            classGroupDescription
            classGroupScheduleOrder
            branch
            scheduleBranchName
            branchLocationLink
            levelDisplayShorten
          }
        }
        fieldValue
      }
    }
  }
`;

export const getTagName = ({ classGroup, inputLevel, outputLevel }) => {
  if (classGroup === 'IELTS Online') return 'Online';
  return `${inputLevel} → ${outputLevel}`;
};

export const filterCourseByNameClassAndCenter = ({
  data,
  filter: { course, clazz, center, levels }
}) => {
  const filteredData = { ...data };

  // map branch name to Online for all Online courses
  filteredData.group = filteredData.group.map(group => ({
    ...group,
    edges: group.edges.map(edge => {
      edge.node = {
        ...edge.node,
        scheduleBranchName: edge.node.level?.includes('Online')
          ? 'Online'
          : edge.node.scheduleBranchName,
        branchLocationLink: edge.node.level?.includes('Online')
          ? 'https://g.page/dolenglish?share'
          : edge.node.branchLocationLink
      };
      return edge;
    })
  }));
  // filter course
  if (course) {
    filteredData.group = filteredData.group.filter(
      item => item.fieldValue === course
    );
  }

  // filter clazz
  if (clazz) {
    filteredData.group = filteredData.group.map(item => ({
      ...item,
      edges: item.edges.filter(edge => edge.node.levelDisplayShorten === clazz)
    }));
  }

  // filter center
  if (center) {
    filteredData.group = filteredData.group.map(item => ({
      ...item,
      edges: item.edges.filter(edge => edge.node.branch === center)
    }));
  }

  if (levels) {
    filteredData.group = filteredData.group.map(item => ({
      ...item,
      edges: item.edges.filter(edge => levels.includes(edge.node.level))
    }));
  }

  // retain avail data
  filteredData.group = filteredData.group.filter(
    group => group.edges.length > 0
  );

  return filteredData;
};

export const groupCourseDataByLevel = data => {
  const result = data.group.map(classGroupData => {
    const groupData = Object.entries(
      groupBy(
        classGroupData.edges
          .filter(row => Boolean(row.node.classDate && row.node.startDate))
          .map(row => {
            const { node } = row;
            const tag = getTagName({
              classGroup: classGroupData.fieldValue,
              inputLevel: node.inputLevel,
              outputLevel: node.outputLevel
            });
            const {
              classTime,
              startDate,
              classStatus,
              classDate,
              classGroupDescription
            } = row.node;
            return {
              ...row.node,
              dateOpening: format(new Date(startDate), 'dd MMMM, yyyy', {
                locale: vi
              }),
              mobileDateOpening: format(new Date(startDate), 'dd/MM'),
              dayOfWeek: formatDayOfWeek(classDate),
              duration: formatClassTime(classTime),
              isFull: classStatus === 'Hết chỗ',
              isAlmostFull: classStatus === 'Gần hết chỗ',
              tag,
              levelKey: `${row.node.level}_+_${tag}`,
              courseDuration: classGroupDescription,
              startDateInMs: new Date(startDate).getTime()
            };
          }),
        'levelKey'
      )
    );

    const classLevel = groupData.map(rowData => {
      const [levelName, tagName] = rowData[0].split('_+_');
      return {
        levelName,
        tagName,
        edges: orderBy(rowData[1], ['startDate'], ['asc'])
      };
    });
    return {
      courseName: classGroupData.fieldValue,
      order: get(classGroupData, 'edges[0].node.classGroupScheduleOrder'),
      courseDuration: get(classLevel, '[0].edges[0].courseDuration'),
      classLevel
    };
  });

  return orderBy(result, 'order');
};

export const useScheduleData = () => useStaticQuery(courseCalendarQuery);
