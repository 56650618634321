import * as React from 'react';
import { InView } from 'react-intersection-observer';
import LazyLoad from 'react-lazyload';

/* eslint-disable react/prop-types */
import cl from 'classnames';
import { motion } from 'framer-motion';
import { StaticQuery, graphql } from 'gatsby';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
import { fromScreen } from 'utils/media-query/responsive.util';

import {
  deviceBreakpoints,
  useDeviceDetect3
} from 'utils/hooks/useDeviceQuery.hook';

import getAssetLink from '../../utils/getAssetLink';

const Main = styled.div`
  min-height: 282px;
  &.scholarship-banner-carousel {
    position: relative;
  }

  ${fromScreen(1144)} {
    min-height: 460px;
  }
`;

const DeckImageMain = styled.div`
  padding: 40px;
  position: relative;
  top: 0;

  .slide-image-item {
    .image-info {
      position: absolute;
      bottom: 14px;
      left: 14px;
      z-index: 6;
      .image-info-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .image-info-description {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        color: #b9b9b9;
        letter-spacing: 0px;
      }
    }
  }

  @media ${deviceBreakpoints.lg} {
    .slide-image-item {
      .image-info {
        bottom: 10px;
        left: 20px;
        .image-info-title {
          font-size: 24px;
          line-height: 32px;
        }
        .image-info-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
`;

const SingleDeckImage = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 196px;
  height: 244px;
  overflow: hidden;
  .image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${fromScreen(1144)} {
    width: 324px;
    height: 404px;
  }
`;

const DeckImageBG = styled(motion.div)`
  position: relative;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const leftRotateArr = [0, -5, 5];
const rightRotateArr = [3, -3, 0];
const imageInDomLimit = 5;

const DeckImage = ({ imageIndex, urls }) => {
  const { isTablet } = useDeviceDetect3();
  const [rightMin, rightMax] = isTablet ? [80, -180] : [40, -340];
  return (
    <DeckImageMain className="slide-image">
      {urls.map((item, index) => {
        const { url, title, description } = item;
        const shouldShowBase64Image = index < 4;
        const shouldVirtualized =
          (index >= imageIndex + imageInDomLimit ||
            index <= imageIndex - imageInDomLimit) &&
          index !== urls.length - 1;

        return (
          <LazyLoad key={url}>
            <SingleDeckImage
              style={{ zIndex: 5 + index }}
              initial={{
                opacity: 0,
                right: index === 0 ? rightMin : rightMax,
                rotate:
                  index === 0
                    ? -5
                    : rightRotateArr[index % rightRotateArr.length]
              }}
              animate={{
                opacity: 1,
                rotate:
                  imageIndex > index
                    ? leftRotateArr[index % leftRotateArr.length]
                    : rightRotateArr[index % rightRotateArr.length],
                right: imageIndex > index ? rightMin : rightMax
              }}
              transition={{ duration: 0.6, ease: [0.5, 1, 0.89, 1] }}
              className={cl('slide-image-item', {
                'first-deck': index === 0,
                'in-right': imageIndex > index
              })}
            >
              {!shouldVirtualized && (
                <>
                  <DeckImageBG />
                  <motion.img
                    src={getAssetLink({ item: { handle: url }, width: 460 })}
                    alt={description}
                    transition={{ duration: 0.8, ease: [0.5, 1, 0.89, 1] }}
                    className="image"
                    loading={shouldShowBase64Image ? 'eager' : 'lazy'}
                  />
                  <div className="image-info">
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        y: imageIndex > index ? 0 : 20,
                        opacity: imageIndex > index ? 1 : 0
                      }}
                      transition={{ duration: 1, delay: 0.1 }}
                      className="image-info-title"
                    >
                      {title}
                    </motion.div>
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        y: imageIndex > index ? 0 : 60,
                        opacity: imageIndex > index ? 1 : 0
                      }}
                      transition={{ duration: 1, delay: 0.1 }}
                      className="image-info-description"
                    >
                      {description}
                    </motion.div>
                  </div>
                </>
              )}
            </SingleDeckImage>
          </LazyLoad>
        );
      })}
    </DeckImageMain>
  );
};

const scholarshipBannerCarouselQuery = graphql`
  query ScholarshipBannerCarouselQuery {
    allGoogleSpreadsheetDolLandingPageResultIelts(
      filter: { listing: { eq: "listed" } }
    ) {
      edges {
        node {
          id
          fullName
          linkHinhDangWeb
          overall
          resultDate
          bangVangDisplayName
        }
      }
    }
  }
`;

let intervalHolder = () => null;

const CustomScholarshipCarousel = ({ urls }) => {
  const [imageIndex, setImageIndex] = React.useState(1);

  const startAutoplayCarousel = () => {
    stopCarouselAutoPlay();
    intervalHolder = setInterval(() => {
      setImageIndex(prevImageIndex => {
        return (prevImageIndex % urls.length) + 1;
      });
    }, 2400);
  };

  const stopCarouselAutoPlay = () => {
    clearInterval(intervalHolder);
  };
  return (
    <InView
      style={{ width: '100%' }}
      onChange={inView => {
        if (inView) {
          startAutoplayCarousel();
        } else {
          stopCarouselAutoPlay();
        }
      }}
    >
      <Main className="scholarship-banner-carousel">
        <DeckImage imageIndex={imageIndex} urls={urls} />
      </Main>
    </InView>
  );
};

const ScholarshipBannerCarousel = () => {
  return (
    <StaticQuery
      query={scholarshipBannerCarouselQuery}
      render={data => {
        const result = data.allGoogleSpreadsheetDolLandingPageResultIelts.edges
          .map(edge => {
            const {
              overall,
              id,
              fullName,
              bangVangDisplayName,
              linkHinhDangWeb,
              resultDate
            } = edge.node || {};
            const nameSplit = (fullName || bangVangDisplayName)?.split(' ');
            const name =
              nameSplit?.length === 1
                ? nameSplit[0]
                : `${nameSplit[nameSplit?.length - 2]} ${
                    nameSplit[nameSplit?.length - 1]
                  }`;

            return {
              ...edge.node,
              id,
              url: linkHinhDangWeb,
              title: `${Number(overall).toFixed(1)} IELTS`,
              description: name,
              score: Number(overall),
              resultDate: new Date(resultDate).getTime()
            };
          })
          .filter(item => item.score >= 7);
        const sortedUrls = orderBy(
          result,
          ['score', 'resultDate'],
          ['desc', 'desc']
        );

        return <CustomScholarshipCarousel urls={sortedUrls} />;
      }}
    />
  );
};

export default ScholarshipBannerCarousel;
