import * as React from 'react';

import format from 'date-fns/format';
import range from 'lodash/range';

const dateFormat = (date, strFormat) => {
  return format(new Date(date || null), strFormat);
};

const mappedDateToNumber = {
  Mon: '2',
  Tue: '3',
  Wed: '4',
  Thu: '5',
  Fri: '6',
  Sat: '7',
  Sun: 'CN'
};

const formatSingleDayOfWeek = date => {
  if (date) {
    const splitHyphen = date.split('-');
    if (date.includes('-') && splitHyphen.length === 2) {
      const indexDate1 = Object.keys(mappedDateToNumber).indexOf(
        splitHyphen[0]
      );
      const indexDate2 = Object.keys(mappedDateToNumber).indexOf(
        splitHyphen[1]
      );
      const minIndex = Math.min(indexDate1, indexDate2);
      const maxIndex = minIndex === indexDate1 ? indexDate2 : indexDate1;
      if (indexDate1 > -1 && indexDate2 > -1) {
        return `Thứ ${range(minIndex, maxIndex + 1)
          .map(index => Object.values(mappedDateToNumber)[index])
          .join('/')}`;
      }
    }
    const splitSplash = date.split('/');
    if (splitSplash.length > 0) {
      if (splitSplash.length === 1 && splitSplash.includes('Sun')) {
        return mappedDateToNumber[date];
      }
      const dayResult = splitSplash
        .map(item => mappedDateToNumber[item])
        .join('/');

      return `Thứ ${dayResult}`;
    }
    return date;
  }
  return '...';
};

export const formatDayOfWeek = date => {
  const splitNewLine = date.split(/\n/g);
  if (splitNewLine.length > 0) {
    return splitNewLine.map((singleDate, index) => (
      <div key={index}>{formatSingleDayOfWeek(singleDate)}</div>
    ));
  }
  return formatSingleDayOfWeek(date);
};

export const formatClassTime = classTime => {
  const splitNewLine = classTime.split(/\n/g);
  if (splitNewLine.length > 0) {
    return splitNewLine.map((singleClassTime, index) => (
      <div key={index}>{singleClassTime}</div>
    ));
  }
  return classTime;
};

export default dateFormat;
