/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { IconCalendarSchedule } from 'components/Icon/SVGIcons';
import cl from 'classnames';
import {
  filterCourseByNameClassAndCenter,
  groupCourseDataByLevel,
  useScheduleData
} from 'shared/SchedulePage/hooks/useScheduleData';
import {
  ScheduleTable,
  TableWrapper
} from 'shared/SchedulePage/components/ScheduleTable';
import { fromScreen, toScreen } from 'utils/media-query/responsive.util';
import { ResponsiveLayout } from 'shared/Layout/ResponsiveLayout';
import { Modal } from 'react-responsive-modal';
import { Drawer } from '@material-ui/core';
import IconClose from 'shared/CourseLanding/icons/Close';

const Container = styled.div`
  margin-top: 32px;
  .intro {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  ${fromScreen(776)} {
    margin-top: 100px;
  }
`;

const Info = styled.div`
  min-height: 170px;
  .contact {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    gap: 4px;
    &.location {
      margin-bottom: 8px;
    }
  }
  .title {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .badge {
    width: fit-content;
    padding: 2px 8px;
    border-radius: 30px;
    color: ${colorsV2.primary100};
    background: ${colorsV2.primary10};
    &.notOpen {
      color: ${colorsV2.yellow100};
      background: ${colorsV2.yellow10};
    }
  }
`;

export const CardList = styled.div`
  display: grid;
  gap: 16px;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid ${colorsV2.gray20};
    border-radius: 8px;
    > a {
      display: block;
      margin-top: 16px;
    }
    .view-map {
      width: fit-content;
      margin: 0 auto;
      position: relative;
      cursor: pointer;
      ::after {
        content: '';
        position: absolute;
        background: ${colorsV2.primary100};
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        z-index: -1;
      }
    }
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    .card {
      padding: 24px;
      ${Info} {
        min-height: 180px;
      }
    }
  }
  ${fromScreen(1144)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  gap: 10px;

  background: ${colorsV2.primary100};
  border-radius: 8px;
  width: 100%;

  > p,
  svg {
    color: ${colorsV2.white100};
  }

  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    background: ${colorsV2.primary80};
  }
  &.disable {
    > p,
    svg {
      color: ${colorsV2.gray60};
    }
    background: ${colorsV2.gray20};
    pointer-events: none;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    line-height: 0;

    svg {
      width: 32px;
      height: 32px;
      color: ${colorsV2.gray100};
      cursor: pointer;
    }
  }
`;
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${ModalHeader} {
    margin-bottom: 32px;
  }

  ${TableWrapper} {
    overflow: auto;
    .table {
      overflow: initial;
      max-width: initial;
    }
  }
`;

/** FOR MOBILE **/
const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colorsV2.gray5};
  padding: 12px 16px;
  border-bottom: 1px solid #f4f4f6;
  .icon {
    line-height: 0;
    svg {
      width: 24px;
      height: 24px;
      color: ${colorsV2.black100};
      cursor: pointer;
    }
  }
`;
const DrawerButton = styled.button`
  padding: 8px 16px;
  border: 2px solid ${colorsV2.gray20};
  border-radius: 8px;
  background: none;
  cursor: pointer;
`;
const DrawerFooter = styled.div`
  width: 100%;
  border-top: 2px solid #f4f4f6;
  padding: 12px 16px;

  ${DrawerButton} {
    width: 100%;
  }
`;
const DrawerWrapper = styled.div`
  ${DrawerHeader} {
    margin-bottom: 12px;
  }

  ${TableWrapper} {
    padding: 0 16px;
    max-height: 65vh;
    overflow: auto;
    ${toScreen(768)} {
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .table {
      overflow: initial;
      max-width: initial;
    }
    .table-body {
      > div:first-child {
        .table-row.expanded:first-child {
          border-top: 0;
          padding-top: 0;
        }
        .table-row.collapsed:first-child {
          border-top: 0;
          padding-top: 0;
        }
      }
    }
  }
`;

const useModalController = () => {
  const [isOpen, setOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setOpen(false);
  }, []);

  return { isOpen, openModal, closeModal };
};

const useCheckSchedule = () => {
  const queryScheduleData = useScheduleData();

  const scheduleNotAvailable = center => {
    return (
      filterCourseByNameClassAndCenter({
        data: queryScheduleData.allGoogleSpreadsheetDolLandingPageMarLevelData,
        filter: {
          center
        }
      }).group.length === 0
    );
  };

  return { queryScheduleData, scheduleNotAvailable };
};

export const BranchOffice = ({ title, desc, listedSchedule }) => {
  return (
    <Container>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
      >
        {title}
      </Typography>
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        v3
        className="intro"
      >
        {desc}
      </Typography>
      <Branch listedSchedule={listedSchedule} />
    </Container>
  );
};

const ListOffice = ({ branchList }) => {
  const { scheduleNotAvailable, queryScheduleData } = useCheckSchedule();

  const { isOpen, openModal, closeModal } = useModalController();
  const [selectedCenter, setSelectedCenter] = React.useState();
  const selectCenter = React.useCallback(
    center => {
      setSelectedCenter(center);
      openModal();
    },
    [openModal]
  );

  const data = groupCourseDataByLevel(
    filterCourseByNameClassAndCenter({
      data: queryScheduleData.allGoogleSpreadsheetDolLandingPageMarLevelData,
      filter: {
        center: selectedCenter ? selectedCenter.id : null
      }
    })
  );

  return (
    <>
      <CardList>
        {branchList.map((center, idx) => {
          const { label, title, location, phone, mapLink, id } = center;
          return (
            <div key={idx} className="card">
              <Info>
                <Typography
                  variant="semi-bold/12-20"
                  className={cl('badge', { notOpen: scheduleNotAvailable(id) })}
                >
                  {label}
                </Typography>
                <Typography variant="semi-bold/20-28" className="title" as="h3">
                  {title}
                </Typography>
                {location && (
                  <div className="contact location">
                    <LazyImage handle="5dfAHONfS3CP2VFDrB71" alt="location" />
                    <Typography variant="medium/14-20" color={colorsV2.black60}>
                      {location}
                    </Typography>
                  </div>
                )}

                <div className="contact">
                  <LazyImage handle="Yf2pyG7OT66esmegZmgz" alt="phone" />
                  <Typography variant="medium/14-20" color={colorsV2.black60}>
                    {phone}
                  </Typography>
                </div>
              </Info>

              {mapLink && (
                <Link to={mapLink} target="_blank" rel="noopener noreferrer">
                  <Typography
                    variant="medium/14-20"
                    color={colorsV2.primary100}
                    className="view-map"
                  >
                    Xem chỉ đường
                  </Typography>
                </Link>
              )}

              <Button
                className={cl({
                  disable: scheduleNotAvailable(id)
                })}
                onClick={() => selectCenter(center)}
              >
                <IconCalendarSchedule />
                <Typography variant="semi-bold/14">Xem lịch học</Typography>
              </Button>
            </div>
          );
        })}
      </CardList>

      <ResponsiveLayout
        breakpointWithComponents={[
          {
            breakpoint: 768,
            renderComponent: (
              <Drawer anchor="bottom" open={isOpen} onClose={closeModal}>
                <DrawerWrapper>
                  <DrawerHeader>
                    <Typography variant="semi-bold/20-28">
                      {selectedCenter && selectedCenter.title}
                    </Typography>
                    <div onClick={closeModal} className="icon">
                      <IconClose />
                    </div>
                  </DrawerHeader>
                  <ScheduleTable data={data} />
                  <DrawerFooter>
                    <DrawerButton onClick={closeModal}>
                      <Typography variant="semi-bold/14-24">Đóng</Typography>
                    </DrawerButton>
                  </DrawerFooter>
                </DrawerWrapper>
              </Drawer>
            )
          },
          {
            breakpoint: 769,
            ssrDefault: true,
            renderComponent: (
              <Modal
                blockScroll={false}
                showCloseIcon={false}
                closeOnOverlayClick={true}
                closeOnEsc={false}
                onClose={closeModal}
                open={isOpen}
                styles={{
                  overlay: {
                    padding: 60
                  },
                  modal: {
                    maxHeight: '100%',
                    maxWidth: '100%',
                    display: 'flex',
                    padding: 24,
                    borderRadius: 8
                  }
                }}
              >
                <ModalWrapper>
                  <ModalHeader>
                    <Typography variant="semi-bold/24-30">
                      {selectedCenter && selectedCenter.title}
                    </Typography>
                    <div onClick={closeModal} className="icon">
                      <IconClose />
                    </div>
                  </ModalHeader>
                  <ScheduleTable data={data} />
                </ModalWrapper>
              </Modal>
            )
          }
        ]}
      />
    </>
  );
};

const Branch = ({ listedSchedule }) => {
  const { scheduleNotAvailable } = useCheckSchedule();

  const data = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "Branch_Office" }) {
          content
        }
      }
    }
  `);

  let dataFilter = [];

  // for schedule
  if (listedSchedule) {
    dataFilter = data.gcms.jsonContanier.content.branch.filter(
      center => !scheduleNotAvailable(center.id)
    );
  } else {
    // for center
    dataFilter = data.gcms.jsonContanier.content.branch.filter(
      center => center.id !== 'Online'
    );
  }

  return <ListOffice branchList={dataFilter} />;
};
