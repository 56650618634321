import * as React from 'react';

/* eslint-disable react/prop-types */
import cl from 'classnames';
import styled from 'styled-components';

const TagMain = styled.div`
  padding: 0 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  height: max-content;

  display: inline-flex;
  &.filled {
    background-color: ${p => p.color || '#242938'};
  }
  &.outline {
    border: 1px solid ${p => p.color || '#242938'};
    background-color: #ffffff;
  }
  &.transparent {
    border: 1px solid ${p => p.color || '#242938'};
  }
  &.bold {
    font-weight: 600;
  }

  color: ${p => p.textColor || 'inherit'};
`;

const Tag = ({
  bold = false,
  children,
  color,
  textColor,
  type = 'outline',
  className,
  ...rest
}) => {
  return (
    <TagMain
      color={color}
      textColor={textColor}
      className={cl('tag', { [type]: true, bold }, className)}
      {...rest}
    >
      {children}
    </TagMain>
  );
};

export default Tag;
