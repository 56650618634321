import * as React from 'react';

import cl from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useLocation } from '@reach/router';
import { colorsV2 } from 'style/colors-v2';
import IconChevronUp from './icons/IconChevronUp';
import IconContentSmile from './icons/IconContentSmile';
import IconInterfaceCalendar from './icons/IconInterfaceCalendar';
import Tag from './Tag';
import { SelectWithPrefix } from './components/SelectWithPrefix';
import { fromScreen } from '../../utils/media-query/responsive.util';
import { getAssetLinkByHandle } from '../../utils/getAssetLink';
import { Typography } from '../../components/DesignSystemV2/Typography';
import {
  groupCourseDataByLevel,
  useScheduleData
} from './hooks/useScheduleData';
import { getName, arrayMove } from 'utils';

const defaultCenterValue = {
  label: 'Quận 10',
  value: '3/2'
};

const defaultCourseValue = {
  label: 'IELTS',
  value: 'IELTS'
};

const getOptionValue = option => {
  if (!option) {
    return null;
  }

  return option.value;
};

/* eslint-disable react/prop-types */
const TAG_COLORS = [
  '#F2AE9D',
  '#A6C7E4',
  '#BAB4DD',
  '#AECFBB',
  '#FFD6A5',
  '#8F92A1',
  '#FF991F',
  '#DE350B',
  '#358756',
  '#2074BB',
  '#5243AA'
];

const IconChevronDown = styled(IconChevronUp)`
  transform: rotate(0deg);
`;

const addMainCenterToTop = arr => {
  const index = arr.findIndex(elm => elm?.value === defaultCenterValue.value);
  arrayMove(arr, index, 1);
  return arr;
};

const addDefaultOption = arr => {
  return [{ label: 'All', value: null }, ...arr].filter(elm => elm.label);
};

const addDefaultOptionCourse = arr => {
  return [defaultCourseValue, ...arr];
};

const FilterWrapper = styled.div`
  display: grid;
  justify-content: start;
  gap: 12px;
  ${fromScreen(457)} {
    grid-auto-flow: column;
  }
`;
const ScheduleFilters = ({ data, onChange, resetFilter, onResetFilter }) => {
  const flattenData = React.useMemo(
    () => data.group.flatMap(item => item.edges).map(item => item.node),
    [data]
  );
  const location = useLocation();
  const centerQueryValue = new URLSearchParams(location.search).get('center');
  const classQueryValue = new URLSearchParams(location.search).get('class');

  const centerQueryLabel = flattenData.find(
    item => item.branch === centerQueryValue
  )?.scheduleBranchName;

  const classQueryLabel = flattenData.find(item =>
    item.classGroup.includes(classQueryValue)
  )?.scheduleBranchName;

  const [selectedCourse, setSelectedCourse] = React.useState(
    classQueryLabel
      ? {
          label: classQueryValue,
          value: classQueryValue
        }
      : defaultCourseValue
  );
  const [selectedClass, setSelectedClass] = React.useState(null);
  const [selectedCenter, setSelectedCenter] = React.useState(
    centerQueryLabel
      ? {
          label: centerQueryLabel,
          value: centerQueryValue
        }
      : null
  );

  React.useEffect(() => {
    if (resetFilter) {
      setSelectedCenter(null);
      onResetFilter();
    }
  }, [onResetFilter, resetFilter]);

  const courseNameOptions = React.useMemo(
    () =>
      addDefaultOptionCourse(
        uniq(flattenData.map(it => it.classGroup))
          .filter(e => !e.includes('IELTS'))
          .map(str => ({
            value: str,
            label: getName(str)
          }))
      ),
    [flattenData]
  );

  const centers = React.useMemo(
    () =>
      addMainCenterToTop(
        addDefaultOption(
          flattenData.map(it => ({
            label: it.scheduleBranchName,
            value: it.branch
          }))
        ).sort()
      ),
    [flattenData]
  );

  // Reset selected class when select new course
  const onSelectCourse = React.useCallback(
    selectedCourse => {
      setSelectedCourse(selectedCourse);
      const courseValue = getOptionValue(selectedCourse);
      const classValue = getOptionValue(selectedClass);

      if (classValue) {
        const foundCourse = flattenData.find(
          it => it.levelDisplayShorten === classValue
        ).classGroup;
        if (courseValue === foundCourse) {
          return;
        }
      }

      setSelectedClass(null);
    },
    [flattenData, selectedClass]
  );

  React.useEffect(() => {
    onChange({
      selectedCourse: getOptionValue(selectedCourse),
      selectedClass: getOptionValue(selectedClass),
      selectedCenter: getOptionValue(selectedCenter)
    });
  }, [selectedCourse, selectedClass, selectedCenter, onChange]);

  return (
    <FilterWrapper>
      <SelectWithPrefix
        title="Khóa:"
        defaultValue={null}
        options={courseNameOptions}
        onChange={value => {
          const url = new URL(window.location.href);
          url.searchParams.set('class', value.label);
          window.history.replaceState(null, '', url.href);
          onSelectCourse(value);
        }}
        value={selectedCourse}
      />
      <SelectWithPrefix
        title="Trung tâm:"
        defaultValue={defaultCenterValue}
        options={uniqBy(
          centers.filter(a => a?.label),
          'label'
        )}
        onChange={value => {
          const url = new URL(window.location.href);
          if (value.value) {
            url.searchParams.set('center', value.value);
          } else {
            url.searchParams.delete('center');
          }
          window.history.replaceState(null, '', url.href);
          setSelectedCenter(value);
        }}
        value={selectedCenter}
      />
    </FilterWrapper>
  );
};

const EmptyLogo = styled.img``;
const EmptyMessage = styled.div``;
const EmptyAction = styled.div`
  cursor: pointer;
`;
const EmptyResultWrapper = styled.div`
  padding: 24px;
  display: grid;
  grid-row-gap: 8px;
  text-align: center;
  place-items: center;
  border: 1px solid ${colorsV2.gray10};
  border-radius: 8px;

  ${fromScreen(768)} {
    padding: 80px;
  }
`;

const EmptyResult = ({ onActionClick }) => {
  return (
    <EmptyResultWrapper>
      <EmptyLogo
        src={getAssetLinkByHandle({ handle: '43LYBqOlTxWXYG4jX32F' })}
      />
      <EmptyMessage>
        <Typography variant="medium/14-20" color={colorsV2.black40}>
          Không có khóa học phù hợp với thông tin cần tìm...
        </Typography>
      </EmptyMessage>
      <EmptyAction>
        <Typography
          variant="regular/14-20"
          color={colorsV2.blue100}
          onClick={onActionClick}
        >
          Show All
        </Typography>
      </EmptyAction>
    </EmptyResultWrapper>
  );
};

const filterCourseByNameClassAndCenter = ({
  data,
  filter: { course, clazz, center }
}) => {
  const filteredData = { ...data };

  // map branch name to Online for all Online courses
  filteredData.group = filteredData.group.map(group => ({
    ...group,
    edges: group.edges.map(edge => {
      edge.node = {
        ...edge.node,
        scheduleBranchName: edge.node.level?.includes('Online')
          ? 'Online'
          : edge.node.scheduleBranchName,
        branchLocationLink: edge.node.level?.includes('Online')
          ? 'https://g.page/dolenglish?share'
          : edge.node.branchLocationLink
      };
      return edge;
    })
  }));
  // filter course
  if (course) {
    filteredData.group = filteredData.group.filter(item =>
      item.fieldValue.includes(course)
    );
  }

  // filter clazz
  if (clazz) {
    filteredData.group = filteredData.group.map(item => ({
      ...item,
      edges: item.edges.filter(edge => edge.node.levelDisplayShorten === clazz)
    }));
  }

  // filter center
  if (center) {
    filteredData.group = filteredData.group.map(item => ({
      ...item,
      edges: item.edges.filter(edge => edge.node.branch === center)
    }));
  }

  // retain avail data
  filteredData.group = filteredData.group.filter(
    group => group.edges.length > 0
  );

  return filteredData;
};

const StyledLink = styled(Link)`
  color: ${colorsV2.blue100};
  text-decoration: underline;
`;

const Main = styled.div`
  position: relative;
  z-index: 11;
  background-color: white;
  .intro {
    margin-top: 12px;
    a {
      color: ${colorsV2.primary100};
    }
  }
  .guide {
    margin-bottom: 24px;
  }
  h3 {
    margin: 0;
  }

  ${FilterWrapper} {
    margin-bottom: 12px;
  }

  .row {
    display: flex;

    &.disabled {
      color: #8f92a1;

      .tag {
        color: #ffffff;
      }
    }

    &.bordered {
      border-bottom: 1px solid #d2d3d9;
    }
  }

  .bg-grey {
    background-color: #f4f4f6;
  }

  .table {
    overflow: auto;
    max-width: 100vw;

    .t-course {
      min-width: 242px;
    }

    .t-course-mobile-name {
      white-space: nowrap;
    }

    .t-course-mobile-duration {
      font-size: 14px;
      line-height: 20px;
      flex: 1;
    }

    .t-class {
    }

    .t-class-opening {
    }

    .t-class-session {
    }

    .t-class-duration {
    }

    .table-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d2d3d9;

      .th {
        display: flex;
        align-items: center;
        padding: 8px;

        .th-icon {
          font-size: 16px;
          line-height: 0;
          margin-right: 6px;
        }

        .th-text {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0px;
        }
      }
    }

    .table-body {
      > div {
        &:not(:last-child) {
          border-bottom: 1px solid #d2d3d9;
        }
      }
      .row-class {
        width: 100%;
        height: 100%;

        > .row {
          min-height: 43px;
          height: 100%;
        }
      }

      .table-row {
        display: flex;

        &.is-one-row {
          height: 100%;
        }

        &.expanded,
        &.collapsed {
          &:first-child {
            padding-top: 16px;
          }

          &:last-child {
            padding-bottom: 16px;
          }
        }

        &.collapsed {
          .t-course {
            flex: 1;
          }

          .row-icon {
            svg {
              transform: rotate(180deg);
            }
          }

          .row-content {
            display: grid;
            grid-gap: 8px;
            grid-auto-flow: column;
            font-size: 14px;
            line-height: 20px;

            .cell-text {
              margin-bottom: 0;
            }
          }

          .table-content {
            display: none;
          }
        }

        .row-icon {
          font-size: 24px;
          display: grid;
          place-items: center;

          svg {
            transition: all 0.3s ease;
          }
        }

        .table-cell {
          padding: 8px;

          .row-icon {
            margin-right: 12px;
            place-items: start;
          }

          .cell-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0px;

            &.bold {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .table {
    .table-row {
      .table-content {
        flex: 1;
      }

      .table-cell {
        padding: 0;
      }

      .t-class {
        border-bottom: none;
      }

      .row-class {
        > .row {
          justify-content: flex-end;
        }
      }

      .t-class-opening {
        border-bottom: none;
      }

      .t-class-session {
        border-bottom: none;
      }

      .t-class-duration {
        border-bottom: none;
      }
    }
  }

  .table-wrapper {
    ${fromScreen(1144)} {
      .table-mobile {
        display: none !important;
      }
      .desktop-only {
        display: block !important;
        &.d-flex {
          display: flex !important;
        }
      }
    }

    overflow: hidden;
    position: relative;
    border: 1px solid ${colorsV2.gray20};
    border-radius: 8px;
    .table {
      &.right-shadow {
        &:after {
          content: '';
          width: 8px;
          position: absolute;
          pointer-events: none;
          top: 0px;
          height: calc(100% - 24px);
          background: linear-gradient(
            to right,
            rgba(99, 114, 130, 0) 0px,
            rgba(9, 30, 66, 0.13) 100%
          );
          left: calc(100% - 8px);
          display: none;
        }
      }

      &.left-shadow {
        &:before {
          content: '';
          width: 8px;
          position: absolute;
          pointer-events: none;
          top: 0px;
          height: calc(100% - 24px);
          background: linear-gradient(
            to left,
            rgba(99, 114, 130, 0) 0px,
            rgba(9, 30, 66, 0.13) 100%
          );
          left: 0;
          display: none;
        }
      }
    }
  }

  .t-class {
    width: 100%;
    max-width: 256px;
  }

  .t-class-opening {
    min-width: 86px;
  }

  .t-class-session {
    min-width: 80px;
  }

  .t-class-duration {
    min-width: 64px;
  }
  .t-class-english-center {
    width: 58px;
  }

  .t-class-head {
    min-width: 120px;
  }

  ${fromScreen(776)} {
    .t-class-head {
      min-width: 300px;
    }
    .t-class-opening {
      min-width: 86px;
    }

    .t-class-session {
      min-width: 112px;
    }

    .t-class-duration {
      min-width: 104px;
    }
    .t-class-english-center {
      width: 92px;
    }
  }

  ${fromScreen(1144)} {
    .table-wrapper {
      .table {
        .t-class-head {
          min-width: 156px;
        }
        &.right-shadow {
          &:after {
            display: none;
          }
        }

        &.left-shadow {
          &:before {
            display: none;
          }
        }

        .table-row {
          justify-content: space-between;
          &.expanded,
          &.collapsed {
            &:first-child {
              border-top: 1px solid ${colorsV2.gray40};
              padding-top: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }

          .table-cell {
            padding: 8px;
          }
        }

        .t-class {
          width: unset;
          min-width: 156px;

          .tag {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .t-class-opening {
          min-width: 221px;
          .tag {
            margin-right: 8px;
          }
        }

        .t-class-session {
          min-width: 129px;
        }

        .t-class-duration {
          min-width: 221px;
        }

        .t-class-english-center {
          width: 109px;
        }

        .row-class {
          flex: 1;

          > .row {
            justify-content: flex-start;

            &:hover {
              background-color: #e9f1f8;
            }
          }
        }
      }
    }
  }
`;
const SectionDolCourseCalendar = () => {
  const [showLeftShadow, setShowLeftShadow] = React.useState(false);
  const [showRightShadow, setShowRightShadow] = React.useState(true);
  const [rowCollapseIndexes, setRowCollapseIndexes] = React.useState([]);

  const [filter, setFilter] = React.useState({});
  const [resetFilter, setResetFilter] = React.useState(false);

  const queryData = useScheduleData();
  const data = groupCourseDataByLevel(
    filterCourseByNameClassAndCenter({
      data: queryData.allGoogleSpreadsheetDolLandingPageMarLevelData,
      filter: {
        course: filter.selectedCourse,
        clazz: filter.selectedClass,
        center: filter.selectedCenter
      }
    })
  );

  return (
    <Main className="section-dol-course-calendar">
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
      >
        Lịch học của DOL
      </Typography>
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        className="intro"
      >
        Tất cả các khóa học ở DOL đều được
        <a href="/dam-bao-dau-ra" target="_blank">
          {' '}
          đảm bảo đầu ra.
        </a>
      </Typography>
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        className="guide"
      >
        Bạn có thể tìm kiếm lịch học theo khóa học và trung tâm mong muốn.
      </Typography>
      <ScheduleFilters
        data={queryData.allGoogleSpreadsheetDolLandingPageMarLevelData}
        resetFilter={resetFilter}
        onResetFilter={() => setResetFilter(false)}
        onChange={setFilter}
      />

      {data.length === 0 ? (
        <EmptyResult onActionClick={() => setResetFilter(true)} />
      ) : (
        <div className="table-wrapper">
          <div
            onScroll={e => {
              try {
                const target = e.target;
                const maxWidth =
                  target.scrollWidth - target.getBoundingClientRect().width;
                setShowRightShadow(target.scrollLeft < maxWidth - 10);
                setShowLeftShadow(target.scrollLeft > 0);
              } catch (ex) {
                console.error(ex);
              }
            }}
            className={cl('table', {
              'right-shadow': showRightShadow,
              'left-shadow': showLeftShadow
            })}
          >
            <div className="table-header   d-flex">
              <div className="th t-course  desktop-only">
                <div className="th-text">Các khóa</div>
              </div>
              <div className="th t-class-head">
                <div className="th-icon">
                  <IconContentSmile />
                </div>
                <div className="th-text">Lớp</div>
              </div>
              <div className="th t-class-opening">
                <div className="th-icon">
                  <IconInterfaceCalendar />
                </div>
                <div className="th-text">Khai giảng</div>
              </div>
              <div className="th t-class-session  desktop-only">
                <div className="th-text">Buổi</div>
              </div>
              <div className="th t-class-duration  desktop-only">
                <div className="th-text">Giờ</div>
              </div>
              <div className="th t-class-english-center  desktop-only">
                <div className="th-text">Trung tâm</div>
              </div>
            </div>
            <div className="table-body">
              {data.map((rowData, rowIndex) => {
                const { courseName, courseDuration, classLevel } = rowData;
                return (
                  <div key={courseName}>
                    <div
                      className={cl(
                        'table-row table-mobile d-flex',
                        rowCollapseIndexes.includes(rowIndex)
                          ? 'collapsed'
                          : 'expanded'
                      )}
                      onClick={() => {
                        if (rowCollapseIndexes.includes(rowIndex)) {
                          setRowCollapseIndexes(prevValue =>
                            prevValue.filter(item => item !== rowIndex)
                          );
                        } else {
                          setRowCollapseIndexes(prevValue => [
                            ...prevValue,
                            rowIndex
                          ]);
                        }
                      }}
                    >
                      <div className="table-cell row t-course-mobile-name">
                        <Typography variant="semi-bold/16-24">
                          {courseName}
                        </Typography>
                      </div>
                      <div className="table-cell row-content t-course-mobile-duration">
                        {courseDuration}
                      </div>
                      <div className="row-icon">
                        <IconChevronDown />
                      </div>
                    </div>
                    <div
                      className={cl(
                        'table-row',
                        'can-expand-collapse',
                        rowCollapseIndexes.includes(rowIndex)
                          ? 'collapsed'
                          : 'expanded'
                      )}
                    >
                      <div
                        onClick={() => {
                          if (rowCollapseIndexes.includes(rowIndex)) {
                            setRowCollapseIndexes(prevValue =>
                              prevValue.filter(item => item !== rowIndex)
                            );
                          } else {
                            setRowCollapseIndexes(prevValue => [
                              ...prevValue,
                              rowIndex
                            ]);
                          }
                        }}
                        className={cl([
                          {
                            'bg-grey': rowCollapseIndexes.includes(rowIndex)
                          },
                          'desktop-only d-flex t-course table-cell row'
                        ])}
                      >
                        <div className="row-icon">
                          <IconChevronDown />
                        </div>
                        <div className="row-content">
                          <h3 className="cell-text bold">{courseName}</h3>
                          {courseDuration}
                        </div>
                      </div>
                      <div className="table-content">
                        {classLevel.map(rowClass => {
                          const { levelName, edges } = rowClass;
                          const color =
                            TAG_COLORS[rowIndex % TAG_COLORS.length];
                          return (
                            <div
                              key={levelName}
                              className={cl('table-row', {
                                'is-one-row':
                                  classLevel.length === 1 && edges.length === 1
                              })}
                            >
                              <div className="t-class row">
                                <div className="table-cell row">
                                  <Tag
                                    className="table-mobile"
                                    color={color}
                                    bold
                                    type="filled"
                                  >
                                    {edges[0].levelDisplayShorten?.replace(
                                      / 8\.0\+/,
                                      ''
                                    )}
                                  </Tag>
                                  <Tag
                                    className="desktop-only"
                                    color={color}
                                    bold
                                    type="filled"
                                  >
                                    {edges[0].levelDisplayShorten?.replace(
                                      / 8\.0\+/,
                                      ''
                                    )}
                                  </Tag>
                                </div>
                              </div>
                              <div className="row-class">
                                {edges.map((rowSchedule, rowScheduleIndex) => {
                                  const {
                                    dateOpening,
                                    dayOfWeek,
                                    duration,
                                    isFull,
                                    mobileDateOpening,
                                    scheduleBranchName,
                                    branchLocationLink,
                                    isAlmostFull
                                  } = rowSchedule;
                                  return (
                                    <div
                                      key={rowScheduleIndex}
                                      className={cl('row', {
                                        disabled: isFull
                                      })}
                                    >
                                      <div className="table-cell no-border t-class-opening">
                                        <div className="cell-text">
                                          <span className="desktop-only">
                                            {dateOpening}
                                          </span>
                                          <span className="table-mobile">
                                            {mobileDateOpening}
                                          </span>
                                        </div>
                                        {isFull && (
                                          <Tag type="filled" color="#DE350B">
                                            Full
                                          </Tag>
                                        )}
                                        {isAlmostFull && (
                                          <Tag
                                            type="filled"
                                            color={colorsV2.yellow100}
                                            textColor={colorsV2.white100}
                                          >
                                            Gần Full
                                          </Tag>
                                        )}
                                      </div>
                                      <div className="table-cell no-border row t-class-session">
                                        <div className="cell-text">
                                          {dayOfWeek}
                                        </div>
                                      </div>
                                      <div className="table-cell no-border row t-class-duration">
                                        <div className="cell-text">
                                          {duration}
                                        </div>
                                      </div>
                                      <div className="table-cell no-border row t-class-english-center">
                                        <div className="cell-text">
                                          <StyledLink
                                            target="_blank"
                                            href={branchLocationLink}
                                            rel="noreferrer noopener"
                                          >
                                            {scheduleBranchName}
                                          </StyledLink>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};

export default SectionDolCourseCalendar;
