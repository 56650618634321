import * as React from 'react';

function IconContentSmile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-3-9a1 1 0 100-2 1 1 0 000 2zm7 2a4 4 0 01-8 0h2a2 2 0 104 0h2zm-1-2a1 1 0 100-2 1 1 0 000 2z"
        fill="#242938"
      />
    </svg>
  );
}

const MemoIconContentSmile = React.memo(IconContentSmile);
export default MemoIconContentSmile;
