import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../../style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

export const ContentWrapper = styled.div``;
export const ActionWrapper = styled.div``;

export const CardWithActionBottomLayout = styled.div`
  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  padding: 12px;
  display: grid;
  grid-row-gap: 16px;

  ${ActionWrapper} {
    align-self: flex-end;
  }

  ${fromScreen(776)} {
    padding: 24px;
  }
`;

export const CardWithActionBottom = ({ Content, Action }) => {
  return (
    <CardWithActionBottomLayout>
      <ContentWrapper>{Content}</ContentWrapper>
      <ActionWrapper>{Action}</ActionWrapper>
    </CardWithActionBottomLayout>
  );
};
export const Tag = styled.div`
  border-radius: 30px;
  padding: 0px 8px;
  background: ${props => props.color || colorsV2.primary20};
`;
